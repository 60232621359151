<template>
    <div>
        <b-overlay :show="show" no-wrap fixed z-index="9999">
            <template v-slot:overlay>
                <div class="d-flex align-items-center">
                    <b-spinner small type="grow" variant="dark"></b-spinner>
                    <b-spinner type="grow" variant="dark"></b-spinner>
                    <b-spinner small type="grow" variant="dark"></b-spinner>
                </div>
            </template>
        </b-overlay>
        <b-card title="نظرات بلاگ">

            <b-table responsive striped hover v-if="items.data" :fields="table_fields" :items="items.data.data">

                <template v-slot:cell(created_at)="data">
                    {{ data.item.created_at | persianDate }}
                </template>

                <template v-slot:cell(status)="data">
                    <span class="badge badge-success" v-if="data.item.status == 1 || data.item.status">تایید شده</span>
                    <span class="badge badge-light" v-else>تایید نشده</span>
                </template>
                <template v-slot:cell(blog_id)="data">
                    {{ data.item.blog.title }}
                </template>
                <template v-slot:cell(name)="data">
                    <span
                        v-if="data.item.user != null ">{{ data.item.user.first_name != null ? data.item.user.first_name  : '' }}
                        {{ data.item.user.last_name != null ?  data.item.user.last_name : '' }}
                        {{ data.item.user.first_name == null && data.item.user.last_name == null ? data.item.name : ''}}</span>
                    <span v-else>{{data.item.name}}</span>
                </template>
                <template v-slot:cell(answer)="data">
                    <button class="btn btn-warning" @click="commnetAnswer(data.index)"><i
                            class="fa fa-comments"></i></button>
                </template>
                <template v-slot:cell(show)="data">
                    <button class="btn btn-info" @click="editItem(data.index)"><i class="fa fa-eye"></i></button>
                </template>
                <template v-slot:cell(delete)="data">
                    <button class="btn btn-danger" @click="deleteItem(data.index)"><i class="fa fa-close"></i></button>
                </template>
            </b-table>
            <div class="alert alert-danger text-right" v-if="items.data && items.data.data == ''">
                <span class="text-right">موردی یافت نشد ! </span>
            </div>
        </b-card>

        <pagination :limit="2" v-if="items.data" :data="items.data" @pagination-change-page="loadItems"></pagination>

        <b-modal id="edit-modal" title="مشاهده نظر" hide-footer size="lg">
            <div v-if="edit.id">
                <div class="d-flex">
                    <label for=""> {{edit.user != null ? 'کاربر : ' : 'نام :' }} </label>
                    <!-- <p v-if>
                        {{edit.user != null &&  edit.user.first_name != null || edit.user.last_name != null !='' ? edit.user.first_name + ' ' +edit.user.last_name : edit.name }}
                    </p> -->
                    <span v-if="edit.user != null ">{{ edit.user.first_name != null ? edit.user.first_name  : '' }}
                        {{ edit.user.last_name != null ?  edit.user.last_name : '' }}</span>
                    <span v-else>{{edit.name}}</span>
                </div>
                <div class="d-flex">
                    <label for="">ایمیل : </label>
                    <p>
                        {{edit.user != null && edit.user.email != null ? edit.user.email : edit.email }}
                    </p>
                </div>
                <div class="d-flex">
                    <label for="">متن : </label>
                    <p>
                        {{edit.comment}}
                    </p>
                </div>

                <div class="mt-2">
                    <b-button @click="approveItem" variant="success"
                        :disabled="edit.status == 1 || edit.status || disabled ? true : false">تایید نظر
                    </b-button>
                    <b-button @click="disapproveItem" variant="danger" class="mr-1"
                        :disabled="edit.status == 0 || !edit.status || disabled ? true : false">رد نظر
                    </b-button>
                </div>
                <b-table class="mt-3" responsive striped hover v-if="edit.children != ''" :fields="comment_fields"
                    :items="edit.children">

                    <template v-slot:cell(created_at)="data">
                        {{ data.item.created_at | persianDate }}
                    </template>

                    <template v-slot:cell(status)="data">
                        <span class="badge badge-success" v-if="data.item.status == 1 || data.item.status">تایید
                            شده</span>
                        <span class="badge badge-light" v-else>تایید نشده</span>
                    </template>
                    <template v-slot:cell(name)="data">
                        <span
                            v-if="data.item.user != null ">{{ data.item.user.first_name != null ? data.item.user.first_name  : '' }}
                            {{ data.item.user.last_name != null ?  data.item.user.last_name : '' }}</span>
                        <span v-else>{{data.item.name}}</span>
                    </template>
                    <template v-slot:cell(answer)="data">
                        <button class="btn btn-warning" @click="commnetAnswer(data.index)"><i
                                class="fa fa-comments"></i></button>
                    </template>
                    <template v-slot:cell(show)="data">
                        <button class="btn btn-info" @click="editComment(data.index)"><i class="fa fa-eye"></i></button>
                    </template>
                    <template v-slot:cell(delete)="data">
                        <button class="btn btn-danger" @click="deleteComment(data.index)"><i
                                class="fa fa-close"></i></button>
                    </template>
                </b-table>
                <!-- <div class="clearfix">
                    <b-button class="float-left" variant="white" type="button"
                        @click="$root.$emit('bv::hide::modal', 'edit-item')">بستن</b-button>
                </div> -->
            </div>
        </b-modal>
        <b-modal id="edit-comment" title="مشاهده نظر" hide-footer>
            <div v-if="editValue.id">
                <div class="d-flex">
                    <label for=""> {{editValue.user != null ? 'کاربر : ' : 'نام :' }} </label>
                    <p v-if="editValue.user != null">
                        {{editValue.user != null &&  editValue.user.first_name != null || editValue.user.last_name != null !='' ? editValue.user.first_name + ' ' + editValue.user.last_name : '' }}
                    </p>
                    <p v-else>
                        {{editValue.name}}
                    </p>
                </div>
                <div class="d-flex">
                    <label for="">ایمیل : </label>
                    <p>
                        {{editValue.user != null && editValue.user.email != null ? editValue.user.email : editValue.email }}
                    </p>
                </div>
                <div class="d-flex">
                    <label for="">متن : </label>
                    <p>
                        {{editValue.comment}}
                    </p>
                </div>

                <div class="mt-2">
                    <b-button @click="approveComment" variant="success"
                        :disabled="editValue.status == 1 || editValue.status || disabled ? true : false">تایید نظر
                    </b-button>
                    <b-button @click="disapproveComment" variant="danger" class="mr-1"
                        :disabled="editValue.status == 0 || !editValue.status || disabled ? true : false">رد نظر
                    </b-button>
                </div>

            </div>
        </b-modal>
        <b-modal id="comment-modal" hide-footer title="پاسخ نظر">
            <form>
                <b-form-group>
                    <label for="answer"> پاسخ <span class="text-danger">*</span></label>
                    <b-form-textarea id="answer" name="body" rows="5"></b-form-textarea>
                </b-form-group>
                <div>
                    <label for="status"> وضعیت </label>
                    <b-form-checkbox switch v-model="answer_selected" id="status" size="lg"> </b-form-checkbox>
                </div>
                <div class="text-center">
                    <button type="submit" class="btn btn-success" :disabled="disabled">ارسال پاسخ</button>
                </div>
            </form>
        </b-modal>
    </div>
</template>
<script>
    import mixins from '../mixins/mixins'

    export default {
        mixins: [mixins],
        data() {
            return {
                url: '/api/admin/blog_comments',
                title: 'نظرات بلاگ',
                comments: [],
                blogs: [],
                blog: [],
                selected_filter_status: '',
                table_fields: [{
                        key: 'id',
                        label: 'شناسه '
                    },
                    {
                        key: 'comment',
                        label: 'نظر'
                    },
                    {
                        key: 'blog_id',
                        label: 'بلاگ'
                    },
                    {
                        key: 'name',
                        label: 'کاربر'
                    },
                    {
                        key: 'status',
                        label: 'وضعیت'
                    },
                    {
                        key: 'created_at',
                        label: 'تاریخ ثبت'
                    },
                    // {
                    //     key: 'answer',
                    //     label: 'پاسخ '
                    // },
                    {
                        key: 'show',
                        label: 'مشاهده '
                    },
                    {
                        key: 'delete',
                        label: 'حذف'
                    }
                ],
                comment_fields: [{
                        key: 'id',
                        label: 'شناسه '
                    },
                    {
                        key: 'comment',
                        label: 'نظر'
                    },
                    {
                        key: 'name',
                        label: 'کاربر'
                    },
                    {
                        key: 'status',
                        label: 'وضعیت'
                    },
                    {
                        key: 'created_at',
                        label: 'تاریخ ثبت'
                    },
                    {
                        key: 'show',
                        label: 'مشاهده '
                    },
                    {
                        key: 'delete',
                        label: 'حذف'
                    }
                ],
                disabled: false,
                comment: {},
                answer_selected: true,
                editValue: {}
            }
        },
        methods: {
            loadComments() {
                this.$axios.get(this.$root.baseUrl + '/api/admin/blog_comments')
                    .then(response => {
                        this.comments = response.data.data
                    })
            },
            loadBlogs() {
                this.$axios.get(this.$root.baseUrl + '/api/admin/blogs')
                    .then(response => {
                        this.blogs = response.data.data.data
                    })
            },
            approveItem() {
                this.disabled = true
                this.$axios.post(this.$root.baseUrl + `/api/admin/blog_comments/${this.edit.id}/approve`)
                    .then(response => {
                        this.edit.status = response.data.data.status
                        window.swal(response.data.message)
                        // this.$root.success_notification(response.message)
                        this.disabled = false
                        this.items.data.data[this.edit.index].status = response.data.data.status
                    })
                    .catch(error => {
                        this.$root.error_notification(error)
                    })
                    .finally(() => {
                        this.disabled = false
                    })
            },
            approveComment() {
                this.disabled = true
                this.$axios.post(this.$root.baseUrl + `/api/admin/blog_comments/${this.editValue.id}/approve`)
                    .then(response => {
                        this.editValue.status = response.data.data.status
                        window.swal(response.data.message)
                        // this.$root.success_notification(response.message)
                        this.disabled = false
                        this.edit.children[this.editValue.index].status = response.data.data.status
                        this.items.data.data[this.edit.index].children[this.editValue.index].status = response.data
                            .data.status
                    })
                    .catch(error => {
                        this.$root.error_notification(error)
                    })
                    .finally(() => {
                        this.disabled = false
                    })
            },
            disapproveItem() {
                this.disabled = true
                this.$axios.post(this.$root.baseUrl + `/api/admin/blog_comments/${this.edit.id}/disapprove`)
                    .then(response => {
                        this.edit.status = response.data.data.status
                        window.swal(response.data.message)
                        // this.$root.success_notification(response.message)
                        this.disabled = false
                        this.items.data.data[this.edit.index].status = response.data.data.status
                    })
                    .catch(error => {
                        this.$root.error_notification(error)
                    })
                    .finally(() => {
                        this.disabled = false
                    })
            },
            disapproveComment() {
                this.disabled = true
                this.$axios.post(this.$root.baseUrl + `/api/admin/blog_comments/${this.editValue.id}/disapprove`)
                    .then(response => {
                        this.editValue.status = response.data.data.status
                        window.swal(response.data.message)
                        this.disabled = false
                        this.edit.children[this.editValue.index].status = response.data.data.status
                        this.items.data.data[this.edit.index].children[this.editValue.index].status = response.data
                            .data.status
                    })
                    .catch(error => {
                        this.$root.error_notification(error)
                    })
                    .finally(() => {
                        this.disabled = false
                    })
            },
            moment(...args) {
                return window.moment(...args)
            },
            commnetAnswer(index) {
                const item = window.clone(this.items.data.data[index])
                item.index = index
                this.comment = item
                this.$root.$emit('bv::show::modal', 'comment-modal')
            },
            editComment(index) {
                const item = window.clone(this.edit.children[index])
                item.index = index
                this.editValue = item
                this.$root.$emit('bv::show::modal', 'edit-comment')
            },
            deleteComment(index) {
                window.swal({
                        title: "آیتم حذف شود؟",
                        text: "این عملیات غیر قابل بازگشت خواهد بود",
                        icon: "warning",

                        showCancelButton: true,
                        showConfirmButton: true,
                        dangerMode: true,
                        buttons: ["خیر", "بله حذف کن"]
                    })
                    .then((willDelete) => {
                        if (willDelete) {
                            const item = window.clone(this.edit.children[index])
                            this.$axios.delete(this.$root.baseUrl + this.url + '/' + item.id)
                                .then((response) => {
                                    this.$root.$delete((this.edit.children), index)
                                    window.swal({
                                        icon: "success",
                                        showConfirmButton: true,
                                        text: response.data.message,
                                        button: "باشه",
                                    })
                                })
                                .catch(error => {
                                    window.swal({
                                        title: 'خطا',
                                        showConfirmButton: true,
                                        text: error.response.data.message,
                                        button: "باشه",


                                    })
                                })
                        }
                    })
                    .catch(() => {

                    })
            }
        },
        created() {
            this.loadBlogs()
            // this.loadComments()
            this.loadItems()
        }
    }
</script>